<template>
    <v-layout>
        <v-flex xs2>
            <v-btn
                fab
                dark
                @click="openComment"
            >
                <v-icon
                    dark
                >
                    comment
                </v-icon>
            </v-btn>
        </v-flex>
        <v-flex xs2 md3 offset-xs6 offset-md9>
            <v-btn
                dark
                fab
                :disabled="valid"
                @click="decrementSingle()"
            >
                <v-icon>remove</v-icon>
            </v-btn>
        </v-flex>
        <v-flex xs2>
            <v-btn
                dark
                fab
                :disabled="valid"
                @click="incrementSingle()"
            >
                <v-icon>add</v-icon>
            </v-btn>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    name: 'normalInput',
    data () {
        return {}
    },
    props: {
        singleCount: {
            type: Number,
            default () {
                return 0
            }
        },
        valid: {
            type: Boolean,
            default () {
                return false
            }
        }
    },
    methods: {
        openComment () {
            this.$emit('openComment')
        },
        incrementSingle () {
            this.$emit('incrementSingle', {
                count: this.singleCount + 1
            })
        },
        decrementSingle () {
            this.$emit('decrementSingle', {
                count: this.singleCount - 1
            })
        }
    }
}
</script>
